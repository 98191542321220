import {
  ByAreahub,
  Footer,
  FooterProps,
  WildfireLogo,
} from "@areahub/components"
import { Box, Heading } from "@chakra-ui/react"
import FooterGraphic from "./FooterGraphic"

export default function WildfireFooter(props: FooterProps) {
  return (
    <Footer
      {...props}
      image={<FooterGraphic />}
      sx={{
        bg: "red.50",
        sx: {
          ".footer-social-links": {
            color: "red.600",
          },
          ".footer-social-link": {
            _hover: {
              color: "red.800",
            },
          },
          ".feedback-button": {
            color: "white",
            bg: "red.800",
            borderColor: "transparent",
            _hover: {
              borderColor: "red.200",
              color: "red.800",
              bg: "transparent",
            },
          },
        },
      }}
    >
      <Heading fontSize="5xl" textAlign="center">
        <WildfireLogo w="full" mb="1" />
      </Heading>
      <Heading
        pos="relative"
        sx={{
          fontFamily: "numerical",
          fontWeight: "700",
          letterSpacing: "-0.025em",
          fontSize: "2xl",
          h: "full",
          my: "auto",
        }}
      >
        Wildfires
        <Box
          as={ByAreahub}
          h="0.75rem"
          pos="absolute"
          w="full"
          mx="auto"
          bottom="-2.5"
        />
      </Heading>
    </Footer>
  )
}
