import * as React from "react"

function FooterGraphic(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1467 692"
      {...props}
    >
      <path
        fill="#FCD7DC"
        d="M1124.58 133.363H911.816v-31.427h18.085V70.05L792.791.456 657.54 69.113v32.152h18.22v179.982h-42.334V90.685h-314.52v37.51h29.474v563.002l626.032-3.477v-1.303h148.758l1.41-553.054"
      />
      <path
        fill="#F9BDC5"
        d="M444.95 480.34l-30.913 30.915h13.223l17.69-17.692V480.34zm0-84.541l-67.13 67.131v13.225l67.131-67.133v-13.225l-.001.002zm.001 56.358l-59.093 59.097h13.225l45.868-45.87v-13.227zm0-28.179L377.82 491.11v13.224l67.13-67.134v-13.224l.001.002zm-.001-56.359l-67.13 67.131v13.225l67.131-67.133V367.62l-.001-.001zm.001-56.359l-67.131 67.133v13.225l67.131-67.132V311.26zm0 28.18l-67.131 67.132v13.225l67.131-67.133V339.44zm0-56.4l-2.509 2.508c.043 0-64.622 64.665-64.622 64.665v13.225l67.131-67.133V283.04zm0-28.18l-30.687 30.688c.042 0-36.444 36.487-36.444 36.487v13.224l67.131-67.133V254.86zm-36.723-131.22a2.072 2.072 0 01-.6-.534l-29.583 29.582c0 .042-.225.269-.225.269v13.224l38.424-38.425c-2.722-1.26-5.406-2.602-8.016-4.116zm36.723 18.5l-66.906 66.906c0 .043-.225.268-.225.268v13.225l67.131-67.133V142.14zm-61.811-22.725l-5.095 5.093c0 .039-.225.264-.225.264v13.224l18.58-18.58h-13.26v-.001zm61.811 50.904l-66.906 66.907c0 .04-.225.266-.225.266v13.227l67.131-67.133v-13.267zm0 56.361l-58.867 58.868c.038 0-8.264 8.304-8.264 8.304v13.224l67.131-67.132V226.68zm0-28.183l-66.906 66.908c0 .042-.225.268-.225.268v13.223l67.131-67.133v-13.266zm-16.504-64.042a43.013 43.013 0 01-2.431-1.559l-47.97 47.972c0 .042-.226.268-.226.268v13.224l56.67-56.67c-2.044-.965-4.053-2.065-6.043-3.235"
      />
      <path
        fill="#FEF0F2"
        d="M970.448 219.726h-27.782v-27.782h27.782v27.782zm51.912 0h-27.775v-27.782h27.775v27.782zm-51.912 44.49h-27.782v-27.781h27.782v27.782-.001zm51.912.001h-27.775v-27.782h27.775v27.782zm51.93-44.491h-27.78v-27.782h27.78v27.782z"
      />
      <path
        fill="#F89C99"
        d="M21.794 688.48c1.292-16.528 15.085-29.546 31.945-29.546a31.924 31.924 0 0115.213 3.836c.976-11.519 10.618-20.567 22.389-20.567 11.671 0 21.263 8.896 22.374 20.277a18.632 18.632 0 0110.514-3.232c9.016 0 16.538 6.37 18.32 14.856h6.293c2.601-3.43 6.709-5.656 11.348-5.656 7.87 0 14.25 6.381 14.25 14.252 0 2.06-.445 4.013-1.23 5.78H21.793"
      />
      <path
        fill="#F5757A"
        d="M1426.33 645.771a5.685 5.685 0 003.37-2.769c.72-1.333.87-2.893.43-4.34l-10.9 3.316c.21.716.57 1.382 1.05 1.96a5.598 5.598 0 001.71 1.409c.66.353 1.39.571 2.13.644.75.073 1.5-.002 2.21-.22"
      />
      <path
        fill="#F89C99"
        d="M1446.15 687.956c0-15.708-12.74-28.442-28.44-28.442-12.71 0-23.45 8.32-27.11 19.802-3.22-6.673-10.02-11.288-17.93-11.288-8.27 0-15.35 5.019-18.37 12.17 0 0-1.52 4.13-1.94 9.03l93.79-1.272z"
      />
      <path
        fill="#fff"
        d="M1286.68 675.087v-.254h41.43v-25.832h.49V368.302h7.18v-11.426h-.4l-18.84-23.69H707.552l.006-59.316.978.356V262.82l-.976-.363v-.553c.024.008 18.808.008 18.808.008l7.038-13.437L404.1 121.182v28.638l.107.04-.097 181.709H144.647l-17.98 26.046h-.342v11.425h7.173V649h.49v25.832h-7.663v11.924H1341.31v-11.67h-54.62"
      />
      <path
        fill="#F89C99"
        d="M402.285 358.012H126.064l18.583-26.442h257.638v26.442zm304.984 0h628.241l-18.59-26.442H707.27v26.442h-.001zm.291-95.554L404.206 149.861l-1.921-30.223 330.86 129.234-7.039 13.437-18.547.15"
      />
      <path
        fill="#F35C64"
        d="M402.285 379.409H133.498v-9.972h268.787v9.972zm926.315 0H707.268v-9.972H1328.6v9.972zM707.565 263.217v11.405L403.248 163.305V150.15l304.317 113.067z"
      />
      <path
        fill="#F89C99"
        d="M492.15 310.273h-41.43v-40.76h41.43v40.76zm79.25 0h-41.43v-40.76h41.43v40.76zm79.25 0h-41.432v-40.76h41.431v40.76h.001zm-158.499 73.088H450.72v-40.762h41.43v40.762h.001zm-7.306 192.9h-41.43v-40.76h41.43v40.76zm187.226 0h-41.43v-40.76h41.43v40.76zm-100.67-192.9H529.97v-40.762h41.43v40.762h.001zm79.249 0h-41.432v-40.762h41.431v40.762h.001zm-.001 71.602H450.72V412.28h199.93v42.683"
      />
      <path
        fill="#F5757A"
        d="M592.525 649.397H516.14V538.96h76.386v110.436l-.001.001zM344.726 688.48h-150.24V500.519h150.239v187.962"
      />
      <path
        fill="#F89C99"
        d="M894 512.163H746.517v-39.171h147.485v39.17l-.002.001zm197.68-.001H944.19v-39.17h147.49v39.17zm196.04 0h-147.48v-39.17h147.48v39.17zm-396.667 93.433H749.832v-63.186h141.222v63.186"
      />
      <path
        fill="#F5757A"
        d="M891.054 686.757H749.832v-81.013h141.222v81.013zm196.306 0H946.134v-81.013h141.226v81.013zm196.79 0h-141.22v-81.013h141.22v81.013z"
      />
      <path
        fill="#F89C99"
        d="M1088.54 605.595H947.322v-63.186h141.218v63.186zm195.61 0h-141.22v-63.186h141.22v63.186z"
      />
      <path fill="#F35C64" d="M458.006 310.273h-7.286v-40.76h7.286v40.76z" />
      <path
        fill="#F35C64"
        d="M450.72 269.513h41.43v6.454h-41.43v-6.454zm86.89 40.76h-7.288v-40.76h7.287v40.76"
      />
      <path
        fill="#F35C64"
        d="M530.322 269.513h41.432v6.454h-41.432v-6.454zm86.89 40.76h-7.287v-40.76h7.286v40.76"
      />
      <path
        fill="#F35C64"
        d="M609.925 269.513h41.432v6.454h-41.432v-6.454zm7.286 113.848h-7.286v-40.762h7.286v40.762"
      />
      <path
        fill="#F35C64"
        d="M609.925 342.599h41.432v6.454h-41.432v-6.454zm-72.316 40.763h-7.287V342.6h7.287v40.762z"
      />
      <path
        fill="#F35C64"
        d="M530.322 342.599h41.432v6.454h-41.432v-6.454zm-72.316 40.763h-7.286V342.6h7.286v40.762z"
      />
      <path
        fill="#F35C64"
        d="M450.72 342.599h41.43v6.454h-41.43v-6.454zM458.006 454h-7.286v-40.76h7.286V454zm295.833 58.956h-7.288v-40.76h7.288v40.76zm140.855 0h-7.287v-40.76h7.287v40.76zM649.723 454h-7.288v-40.76h7.288V454z"
      />
      <path
        fill="#F35C64"
        d="M450.72 413.24h199.93v6.454H450.72v-6.454zm500.446 99.717h-7.287v-40.76h7.287v40.76zm140.854 0h-7.29v-40.76h7.29v40.76zm56.47 0h-7.28v-40.76h7.28v40.76zm140.86 0h-7.29v-40.76h7.29v40.76zm-148.18 31.737h142.81v6.454h-142.81v-6.454zm-195.123 0h142.803v6.454H946.047v-6.454zm-197.188 0h142.799v6.454h-142.8v-6.454h.001zm-264.014-3.4h-41.43V535.5h41.43v5.793"
      />
      <path
        fill="#F35C64"
        d="M444.748 535.5h5.425v40.761h-5.425V535.5zm34.672.001h5.425v40.76h-5.425v-40.76zm192.651 5.793h-41.43V535.5h41.43v5.794zm-79.546 2.606h-78.16v-5.794h78.16v5.794z"
      />
      <path
        fill="#F35C64"
        d="M630.642 535.5h5.425v40.761h-5.425V535.5zm-112.887 3.461h5.426v109.33h-5.426v-109.33zm67.776 0h5.425v109.33h-5.425v-109.33zm81.115-3.46h5.425v40.76h-5.425v-40.76zm-263.398-58.697l-13.106 19.158V368.698h13.106v108.106zm.266 198.427h-13.372V507.857h13.372v167.374zm302.28-198.427l13.106 19.158V368.698h-13.107v108.106h.001zm-.266 198.427H718.9V507.857h-13.372v167.374z"
      />
      <path
        fill="#F35C64"
        d="M707.565 517.628H402.281v-9.77h305.284v9.77zm68.517 89.33l-27.583 27.583v13.225l40.807-40.807h-13.224v-.001zm-27.583.001v12.624l12.625-12.624h-12.625zm83.94 0l-80.183 80.182h13.225l80.183-80.182h-13.225zm56.057.301l-79.88 79.88h13.223l66.657-66.656V607.26zm-27.876-.301l-80.184 80.182h13.224l80.183-80.182H860.62zm-56.359 0L748.5 662.722v13.222l68.985-68.985H804.26h.001zm84.234 28.48l-51.701 51.702h13.225l38.476-38.476v-13.226zm113.025-28.48l-55.249 55.247v13.223l68.469-68.47h-13.22zm253.62 0l-80.19 80.182h13.23l80.19-80.182h-13.23zm28.17 0l-80.18 80.182h13.22l68.71-68.704v-11.478h-1.75zm1.75 26.433l-53.75 53.749h13.23l40.52-40.526v-13.223zm-58.11-26.433l-80.18 80.182H1160l80.18-80.182h-13.23zm-280.678 0v12.108l12.108-12.108h-12.108zm-57.777 56.661l-23.52 23.52h13.222l10.298-10.296V663.62zm310.285-56.661l-55.18 55.176v13.225l68.41-68.401h-13.23zm86.28 54.616l-25.56 25.566h13.21l12.35-12.341v-13.225zm-255.36-54.616l-80.185 80.182h13.225l80.18-80.182h-13.22zm28.18 0l-80.186 80.182h13.223l80.183-80.182h-13.22zm112.72 0l-27 26.997v13.223l40.22-40.22h-13.22zm-197.26 0l-27.069 27.067v13.224l40.293-40.291H973.34zm170.26 0V619l12.04-12.042-12.04.001zm-57.78 56.593l-23.58 23.589h13.22l10.36-10.364v-13.225zm0-56.361l-79.95 79.95h13.23l66.72-66.726v-13.224zm0 28.178l-51.77 51.772h13.23l38.54-38.547v-13.225zm-765.792-133.74L195.141 626.514v13.225l138.112-138.11h-13.225zm21.814 6.364L195.141 654.695v13.222l146.701-146.7v-13.224zm0 28.18L195.141 682.874v4.267h8.957l137.744-137.745v-13.223zm0 28.182L219.056 687.14h13.224l109.562-109.56v-13.225zm0 112.717l-10.07 10.069h10.07v-10.069zM291.85 501.628l-96.708 96.707v13.226l109.934-109.933H291.85zm49.993 147.265l-38.248 38.248h13.224l25.024-25.025v-13.223zm0-56.359l-94.607 94.607h13.224l81.383-81.383v-13.224zm0 28.18l-66.427 66.427h13.225l53.202-53.203v-13.225.001zM235.49 501.628l-40.35 40.35v13.225l53.575-53.575H235.49zm-28.182 0l-12.167 12.167v13.223l25.391-25.39h-13.224zm56.363 0l-68.53 68.53v13.224l81.754-81.754H263.67"
      />
      <path
        fill="#F35C64"
        d="M203.634 688.48h-7.288V501.647h7.288v186.835-.002zm139.3.002h-7.285V501.646h7.285v186.835"
      />
      <path
        fill="#F35C64"
        d="M196.493 502.57h142.799v6.455h-142.8v-6.454l.001-.001zm693.832 185.911h-7.288V543.769h7.288v144.712zm-130.681 0h-7.288V543.769h7.288v144.712zm194.52 0h-7.286V543.769h7.286v144.712zm130.686 0h-7.29V543.769h7.29v144.712zm67.11 0h-7.29V543.769h7.29v144.712zm132.02 0h-7.3V543.769h7.3v144.712zm-846.14-13.25h69.088v13.25h-84.754l15.666-13.25zm-254.66 0h-21.45l-15.667 13.25h37.117v-13.25zm206.962 0h-33.279l-.141 13.25h33.42v-13.25zm360.418 0h-33.278l-.141 13.25h33.42v-13.25h-.001zm195.574 0h-53.792l-.228 13.25h54.02v-13.25zm197.366 0h-53.79l-.23 13.25h54.02v-13.25zm197.86 0h-53.78l-.23 13.25h54.01v-13.25zm-863.018-25.834h28.586v25.834h-51.755l23.169-25.834zm191.915 25.834h-69.088v13.25h84.754l-15.666-13.25zm-40.5-25.834h-28.588v25.834h51.755l-23.167-25.834z"
      />
      <path
        fill="#fff"
        d="M1025.45 473.184l-39.112 39.11h13.225l39.107-39.11h-13.22zm27.37 0l-39.11 39.11h13.22l39.11-39.11h-13.22zm168.09 0l-39.11 39.11h13.23l39.11-39.11h-13.23zm27.38 0l-39.12 39.11h13.22l39.12-39.11h-13.22zm-428.773 0l-39.11 39.11h13.224l39.11-39.11h-13.224zm27.367 0l-39.11 39.11H821l39.11-39.11h-13.226z"
      />
      <path
        fill="#F35C64"
        d="M746.516 473.121h147.485v6.454H746.516v-6.454zm197.329 0h147.485v6.454H943.845v-6.454zm197.325 0h147.49v6.454h-147.49v-6.454z"
      />
      <path
        fill="#391831"
        d="M24.121 688.662l-4.653-.362c1.39-17.776 16.443-31.7 34.27-31.7 4.602 0 9.171.931 13.389 2.71 2.486-11.13 12.549-19.44 24.214-19.44 11.655 0 21.483 7.936 24.12 18.953 5.91-2.713 13.165-2.475 18.915.693l-2.254 4.088a16.389 16.389 0 00-7.893-2.022c-3.3 0-6.482.977-9.202 2.828l-3.254 2.213-.382-3.916c-1.01-10.359-9.63-18.17-20.05-18.17-10.376 0-19.188 8.095-20.064 18.43l-.3 3.548-3.133-1.692a29.779 29.779 0 00-14.105-3.556c-15.409 0-28.417 12.034-29.618 27.395zm1330.579.76l-4.66-.386c.43-5.159 2-9.466 2.07-9.647 3.53-8.358 11.58-13.694 20.56-13.694 6.97 0 13.33 3.17 17.49 8.508 5.14-10.345 15.69-17.023 27.55-17.023 16.96 0 30.77 13.807 30.77 30.776h-4.67c0-14.396-11.72-26.11-26.1-26.11-11.43 0-21.43 7.306-24.88 18.178a2.32 2.32 0 01-.79 1.127c-.37.288-.81.459-1.28.491-.46.029-.93-.08-1.33-.313-.4-.234-.73-.581-.94-.999-2.96-6.149-9.02-9.969-15.82-9.969a17.622 17.622 0 00-9.74 2.926 17.644 17.644 0 00-6.49 7.821h.02s-1.4 3.85-1.76 8.314"
      />
      <path
        fill="#391831"
        d="M1466.75 690.814H.371v-4.666H1466.75v4.666zM185.146 651.73h-54.241V367.104h274.942v110.08h-4.666V371.772H135.57v275.292h49.575v4.667l.001-.001zm220.7.001h-49.125v-4.667h44.459V506.961h4.666V651.73"
      />
      <path
        fill="#391831"
        d="M128.397 367.104h273.108v-32.805H145.427l-17.03 24.445v8.36zm277.774 4.666h-282.44v-14.49l19.259-27.647h263.181v42.137zM128.397 686.148h53.284v-8.584h-53.284v8.584zm57.95 4.666H123.73v-17.917h62.618v17.917"
      />
      <path
        fill="#391831"
        d="M135.68 672.897h46.001V651.73h-46l-.001 21.167zm50.668 4.667h-55.334v-30.5h55.334v30.5zm562.28-25.834h-43.692V510.505h4.667v136.558h39.025v4.667zm197.237 0H891.05v-4.667h54.815v4.667zm197.245 0h-55.49v-4.667h55.49v4.667zm187.56 0h-46.19v-4.667H1326V371.032H709.602v104.674h-4.667V366.365h625.735V651.73"
      />
      <path
        fill="#391831"
        d="M709.603 366.365h623.577v-8.275l-17.75-22.175H709.603v30.45zm628.237 4.667H704.936v-39.783h612.734l20.17 25.203v14.58zM945.865 677.816h-54.812v-4.667h54.812v4.667zm197.055 0h-55.3v-4.667h55.3v4.667"
      />
      <path
        fill="#391831"
        d="M1343.39 690.814H716.565v-17.665h32.268v4.667h-27.601v8.332h617.488v-8.332h-52v-4.667h56.67v17.665"
      />
      <path
        fill="#391831"
        d="M709.603 672.897h37.896V651.73h-37.896v21.167zm42.562 4.667h-47.229v-30.5h47.23v30.5h-.001zm535.875-4.667H1326v-21.842h-37.96v21.842zm42.63 4.667h-47.29v-31.175h47.29v31.175zM709.605 476.349l-4.666-.001.05-212.414-298.818-110.368-.066 322.566-4.667-.002.07-329.26 308.148 113.814-.05 215.665"
      />
      <path
        fill="#391831"
        d="M707.74 259.976h16.955l5.17-9.87-323.692-125.123v23.609L707.74 259.976zm19.778 4.666h-20.611l-.392-.144-305.009-112.654v-33.668l334.917 129.461-8.905 17.005zM392.476 686.148h324.09v-8.584h-324.09v8.584zm328.757 4.666H387.809v-17.917h333.424v17.917"
      />
      <path
        fill="#391831"
        d="M405.847 672.897h299.12V651.73h-299.12v21.167zm303.786 4.667H401.181v-30.5h308.452v30.5zm-512.814 8.584h145.573v-183.37H196.819v183.37zm150.24 4.666H192.152V498.111h154.905v192.703"
      />
      <path
        fill="#391831"
        d="M347.058 686.148h7.487V491.702H186.348v194.446h5.805V498.186h154.905v187.962zm12.153 4.666h-16.82V502.852H196.82v187.962h-15.138V487.035h177.53v203.78l-.001-.001zM748.85 509.828h142.817v-34.504H748.85v34.504zm147.484 4.667H744.183v-43.838h152.15v43.838h.001zm50.017-4.667h142.819v-34.504H946.351v34.504zm147.489 4.667H941.685v-43.838h152.155v43.838zm48.29-4.667h142.82v-34.504h-142.82v34.504zm147.49 4.667h-152.15v-43.838h152.15v43.838zM509.878 647.062h88.906v-116.28h-88.906v116.28zm93.572 4.667h-98.238V526.116h98.238V651.73"
      />
      <path
        fill="#391831"
        d="M518.473 646.791h71.72V539.51h-71.72v107.281zm76.386 4.667h-81.053V534.842h81.053v116.616"
      />
      <path
        fill="#391831"
        d="M509.26 686.148h89.633V651.73h-89.632l-.001 34.418zm94.3 4.666h-98.966v-43.75h98.966v43.75zM453.053 307.94h36.764v-36.094h-36.764v36.094zm41.43 4.666h-46.097V267.18h46.098v45.427l-.001-.001zm-48.735 261.323h36.764v-36.094h-36.764v36.094zm41.43 4.666h-46.097v-45.427h46.098v45.427h-.001zm145.496-4.666h36.764v-36.094h-36.764v36.094zm41.43 4.666h-46.097v-45.427h46.098v45.427h-.001zM532.655 307.94h36.766v-36.094h-36.766v36.094zm41.432 4.666h-46.099V267.18h46.1v45.427l-.001-.001zm38.171-4.665h36.766v-36.094h-36.766v36.094zm41.433 4.666h-46.1V267.18h46.1v45.427zm-200.638 68.421h36.764v-36.095h-36.764v36.095zm41.43 4.667h-46.097v-45.428h46.098v45.428h-.001zm-41.43 65.013h195.263v-36.094H453.053v36.094zm199.93 4.666H448.386v-45.426h204.597v45.426zm-120.328-74.346h36.766v-36.095h-36.766v36.095zm41.432 4.667h-46.099v-45.428h46.1v45.428h-.001zm38.171-4.667h36.766v-36.095h-36.766v36.095zm41.433 4.667h-46.1v-45.428h46.1v45.428zm98.837 300.454H889.61V544.742H752.528v141.407zm141.749 4.666H747.862v-150.74h146.415v150.74"
      />
      <path
        fill="#391831"
        d="M752.528 603.262h136.193v-58.52H752.528v58.52zm140.86 4.666H747.861v-67.853h145.525v67.853h.002zm54.813 78.22h137.079V544.742H948.201v141.406zm141.749 4.666H943.534V540.075h146.416v150.74"
      />
      <path
        fill="#391831"
        d="M948.203 603.262H1084.4v-58.52H948.203v58.52zm140.857 4.666H943.537v-67.853h145.523v67.853zm56.39 78.22h137.08V544.742h-137.08v141.406zm141.74 4.666h-146.41V540.075h146.41v150.74"
      />
      <path
        fill="#391831"
        d="M1145.44 603.262h136.21v-58.52h-136.21v58.52zm140.87 4.666h-145.53v-67.853h145.53v67.853zM795.34 446.227h456.14v-39.531H795.342l-.002 39.531zm460.81 4.667H790.676v-48.865h465.474v48.865"
      />
      <path
        fill="#391831"
        d="M869.956 438.123v-24.315h17.231v4.418h-12.71v6.645h12.026v4.242h-12.026v9.01h-4.521zm20.735 0v-24.349h4.486v24.35h-4.486v-.001zm13.293-20.07v7.79h6.885c2.876 0 4.111-1.947 4.111-3.894 0-1.948-1.27-3.896-4.11-3.896h-6.886zm16.716 20.07h-5.379l-6.989-8.14h-4.348v8.14h-4.52V413.74c3.802 0 7.604.034 11.405.034 5.651.034 8.632 3.862 8.632 8.068 0 3.341-1.505 6.717-6.065 7.655l7.264 8.314v.312zm19.743 0h-17.95v-24.349h17.95v4.452h-13.46v5.602h12.983v4.275h-12.984v5.5h13.461v4.52zm14.607-4.418h4.933c5.445 0 7.604-4.034 7.501-8.002-.104-3.789-2.296-7.582-7.501-7.582h-4.933v15.584zm16.922-7.964c.105 6.156-3.597 12.382-11.99 12.382h-9.42v-24.349h9.42c8.22 0 11.886 5.948 11.99 11.967zm20.875 12.382H974.9v-24.349h17.948v4.452h-13.46v5.602h12.982v4.275h-12.983v5.5h13.461v4.52h-.001zm8.033-11.376h6.78c5.79 0 5.75-8.626 0-8.626h-6.78v8.626zm6.78 4.211h-6.78v7.165h-4.526v-24.349c3.766 0 7.536-.034 11.306-.034 11.71 0 11.75 17.218 0 17.218zm22.62-12.072l-4.45 10.367h8.9l-4.45-10.367zm6.27 14.645h-12.54l-2.06 4.592h-4.89l10.75-24.349h4.93l10.76 24.35h-4.93l-2.02-4.593zm13.54-15.478v7.79h6.89c2.88 0 4.11-1.947 4.11-3.894 0-1.948-1.27-3.896-4.11-3.896h-6.89zm16.72 20.07h-5.38l-6.99-8.14h-4.35v8.14h-4.52V413.74c3.8 0 7.61.034 11.41.034 5.65.034 8.63 3.862 8.63 8.068 0 3.341-1.51 6.717-6.06 7.655l7.26 8.314v.312zm9.12-20.175h-7.6v-4.174h19.73v4.174h-7.6v20.175h-4.53v-20.175zm35.78 2.538l-7.81 10.575h-.89l-7.64-10.608v17.705h-4.52v-24.35h5.2l7.51 10.472 7.5-10.472h5.17v24.35h-4.52v-17.672zm26.67 17.637h-17.95v-24.349h17.95v4.452h-13.47v5.602h12.99v4.275h-12.99v5.5h13.47v4.52zm19.98-24.383h4.52v24.383h-2.81c0 .035-12.6-16.42-12.6-16.42v16.42h-4.53v-24.349h3.67l11.75 15.097V413.74zm14.88 4.208h-7.6v-4.174h19.73v4.174h-7.61v20.175h-4.52v-20.175zm-781.16 87.576h327.33v-4.996l-12.49-21.846H404.854L392.08 500.54v4.984zm331.994 4.667h-336.66v-10.913l14.76-25.262h307.453l14.447 25.272v10.903zm-321.79-149.846h-276.22v-4.666h276.22v4.666z"
      />
      <path
        fill="#391831"
        d="M721.742 502.242H389.748v-4.667h331.994v4.667zM601.117 664.647h-94.19v-4.667h94.19v4.667zm734.393-305.042H707.268v-4.666h628.242v4.666zM553.018 453.04h-4.666v-40.76h4.666v40.76z"
      />
    </svg>
  )
}

export default FooterGraphic
