import { Navbar as Nav } from "@areahub/components"
import nav from "configs/nav"
// @ts-ignore
const Navbar = (props: any) => (
  <Nav
    {...nav}
    {...props}
    sx={{
      ...nav?.sx,
      ...props?.sx,
    }}
  />
)

export default Navbar
