import { processEnv } from "@areahub/lib"

export const mainSite = processEnv.isDeployedToProd
  ? "https://areahub.com"
  : "https://staging.areahub.info"
export const externalLink = {
  target: "_blank",
  rel: "noopener noreferrer",
}

export const knowledgeCenterLink = `${mainSite}/knowledge-center`

export const noOutline = {
  _focus: {
    outline: "none",
  },
}

export const disclaimer = `AreaHub strives to provide wildfire information as accurately, clearly, and quickly as it can. To do so, it combines multiple datasets from the National Interagency Fire Center and/or other sources (including EPA's AirNow where recent air quality data is displayed) with a clear and integrated approach. AreaHub seeks real-time information as available. Nevertheless, issues may occur that can affect wildfire reporting. For example, the responding fire departments or agencies may update their wildfire reporting on a less frequent basis, so please note the timing of any “last updated” disclosures for large fires. In addition, any data categories not provided by the responding team may show up as “0”, “n/a”, or “null”.`
