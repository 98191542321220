import { Box, BoxProps } from "@chakra-ui/layout"
import Footer from "components/Footer"
import Navbar from "components/Navbar"
import footer from "configs/footer"
import { navHeight } from "configs/nav"
import { Fragment, PropsWithChildren } from "react"

export interface LayoutProps extends PropsWithChildren<BoxProps> {
  navSx?: any
}

export default function Layout({ navSx, ...props }: LayoutProps) {
  return (
    <Fragment>
      <Navbar {...navSx} />
      <Box as="main" mt={navHeight} pos="relative" bg="white" {...props}>
        {props.children}
      </Box>
      <Footer {...footer} />
    </Fragment>
  )
}
