import { externalLink, mainSite, } from "./common"
export default {
  cols: [
    {
      header: "Product",
      links: [
        { url: `${mainSite}/about`, label: "Our Story", ...externalLink },
        {
          url: `${mainSite}/knowledge-center`,
          label: "Knowledge Center",
          ...externalLink,
        },
        {
          url: `${mainSite}/legal/advertising-and-partnerships`,
          label: "Advertising & Partnerships",
          ...externalLink,
        },
      ],
    },
    {
      header: "Legal",
      links: [
        {
          url: `${mainSite}/legal/privacy`,
          label: "Privacy Policy",
          ...externalLink,
        },
        {
          url: `${mainSite}/legal/terms-of-use`,
          label: "Terms of Use",
          ...externalLink,
        },
        {
          url: `${mainSite}/legal/data-sources`,
          label: "Data Sources",
          ...externalLink,
        },
        {
          url: `${mainSite}/legal/data-and-scoring`,
          label: "Data & Scoring",
          ...externalLink,
        },
      ],
    },
  ],
}
