/* eslint-disable react/jsx-no-target-blank */
import { AreaHubLogo } from "@areahub/components"
import { processEnv } from "@areahub/lib"
import { Button } from "@chakra-ui/button"
import Link from "next/link"

export const LinkButton = ({ href, children, ...props }: any) => (
  <Link href={href} passHref>
    <Button
      as="a"
      variant="ghost"
      colorScheme="red"
      {...props}
      w={{
        base: "full",
        lg: "auto",
      }}
    >
      {children}
    </Button>
  </Link>
)
export const navHeight = {
  base: "48px",
  lg: "56px",
}
const nav = {
  brand: {
    name: "Wildfires by Areahub",
    label: "",
    href: processEnv.isDeployedToProd
      ? "https://areahub.com"
      : "https://staging.areahub.info",
    logo: <AreaHubLogo h="8" />,
  },
  bg: "white",
  sx: {
    pos: "fixed",
    top: 0,
    right: 0,
    left: 0,
    zIndex: "modal",
  },
  links: [
    {
      children: (
        <a href="https://areahub.com/about" target="_blank">
          About Us
        </a>
      ),
      colorScheme: "red",
    },
    <LinkButton key={"interactive-map-link"} href="/now">
      Nationwide Map
    </LinkButton>,
    {
      children: (
        <a href="https://areahub.com/knowledge-center" target="_blank">
          Knowledge Center
        </a>
      ),
      colorScheme: "red",
    },
  ],
  cta: {
    children: (
      <a
        target="_blank"
        href={
          processEnv.isDeployedToProd
            ? "https://areahub.com"
            : "https://staging.areahub.info"
        }
      >
        Go to AreaHub
      </a>
    ),
    colorScheme: "red",
  },
}

export default nav
